
:root,
[data-theme="light"] {
    --border-custom-color: #eee;
    --theme-bg-color: #ffffff;
    .heroImageStyles{
        background-color: var(--kt-header-fixed-bg-color);
    }
}
[data-theme="dark"] {
    --border-custom-color: #5c5c5c;
    --theme-bg-color: #151521;
    .no-box-shadow {
        background-color: var(--kt-card-bg);
    }
  }

.ulGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
    // gap: 10px;
    // margin: 24px 0;
    list-style: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .ulGrid {
        grid-template-columns: repeat(1, 1fr);
    }

}
@media only screen and (min-width: 768px) and (max-width: 1080px) {
    .ulGrid {
        grid-template-columns: repeat(2, 1fr);
    }

}
.mainSelectionDiv {
    cursor: pointer;
    border: 1px solid var(--border-custom-color);
    border-radius: 10px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 4%);
    padding: 8px;
    height: 100%;
    transition: all 200ms;
}
.priceContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.nameRow {
    display: flex;
}

.priceContent:not(:empty) {
    margin-top: 3px;
    margin-inline: 3px;
}
.priceContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

._name_1yf3bj {
    font-family: Inter,Helvetica,Arial,sans-serif;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
    font-size: 1.575rem;
    text-align: left;
    padding: 0;
    flex-grow: 1;
    cursor: pointer;
    border: none;
    background: none;
}

.mainSelectionDiv.isSelected {
    border: 3px solid var(--kt-primary);
    border-radius: 10px;
}

.isSelected .isChecked {
    visibility: visible; 
}

#headerTitleId {
    border: none;
    border-bottom: 2px solid var(--kt-primary);
    color: var(--kt-primary);
    display: inline;
    font-weight: bolder;
    padding: 0;
    position: relative;
    transition: color 200ms,border-bottom 200ms;
    text-decoration: none;
    cursor: pointer;
}

._breadcrumbs_g443oc {
    margin-top: 8px;
    margin-bottom: 16px;
}
.headerRootLinkTitle {
    display: flex;
}
.headerLinkTitle {
    display: flex;
    align-items: center;
}

.headerLinkTitle:not(:last-child)::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: inline-block;
    height: 10px;
    transform: rotate(45deg);
    vertical-align: center;
    width: 10px;
    margin-right: 8px;
    margin-left: 4px;
    flex-shrink: 0;
    color: black;
}

.menuItemImageStyles {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

.alterPlaceHolderImage {
    filter: brightness(0%);
    opacity: .1;
}
.order-details-footer{
    height: 70px;
    background-color: var(--kt-header-fixed-bg-color);
}
.order-footer-button{
    padding: 15px 0px;
	font-size: 25px;
	border-radius: 10px;
	height: 70px;
}
.heroImageStyles {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media(max-width: 770px) {
    .heroImageStyles {
        height: 100% !important;
        background-image: none !important;
        padding: 0 !important;
        .heroImageDiv {
            height: 200px !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1351px) {
    .order-details-footer{
      height: 70px;
    }
}

@media (max-width: 400px){
    .order-footer-button{
        height: 50px;
    }
}

.activeNavItem {
    padding-bottom: 14px;
    align-self: baseline;
    border-bottom: 2px solid var(--kt-primary);
}

.navItemContainer {
    display: flex;
    white-space: nowrap;
}

.nav-header-link {
    font-family: Inter,Helvetica,Arial,sans-serif;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    color: var(--kt-primary);
    user-select: text;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px;
    background: transparent;
}
.nav-header-link:hover{
    text-decoration: none;
    color: var(--kt-primary);

}


@media (min-width: 766px){
    .heroImageDiv {
        width: 430px;
    }
}

.stickyNav {
    display: flex;
    // border-bottom: 1px solid #ddd;
    min-height: 54px;
    transition: opacity 275ms;
    overflow: auto;
    scrollbar-width: none;
}

[data-theme="dark"]{
    .stickyNav {
        background-color: #151521;
    }
    .accordion-header > .collapsed{
        background-color: #171723 !important;
    }
}

[data-theme="light"]{
    .stickyNav {
        background-color: #f5f8fa;
    }
    .accordion-header > .collapsed{
        background-color: #eef3f7 !important;
    }
}
.activeNavItem {
    padding-bottom: 14px;
    border-bottom: 2px solid var(--kt-primary);
}

.navItemContainer {
    display: flex;
    white-space: nowrap;
}

.nav-header-link {
    font-family: Inter,Helvetica,Arial,sans-serif;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    color: var(--kt-primary);
    user-select: text;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px;
    background: transparent;
}
.nav-header-link:hover{
    text-decoration: none;
    color: var(--kt-primary);

}



.sticky-top{
    background-color: var(--theme-bg-color);
}
@media(max-width:1000px){
    .sticky-top{
        top: 55px !important;
    }
}

.accordion-item-section{
    scroll-margin-top: 115px;
}


.ord-add-to-cart-btn{
    padding: 2px 0px !important;
    border-radius: 10px !important;
    height: 50px !important;
}
.itemslider{
      display: inline-block !important;
}

.view_all_item{
    width: 200px !important;
    min-width: 200px !important; 
    max-width: 200px !important;
    height: 275px !important;
     
   }

.quantity-input{
    width: 100%;
}

#cancel-layout-small{
    display: none !important;
}

   /* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 550px) {
    #mobile-size-container {
        padding-bottom: 110px;
        padding-top: 12px;
    }
    #add-cart-layout {
        display: flex;
        justify-content: center !important;
        flex-wrap: wrap;
        align-items: end;
    }
    #add-cart-width {
        width: 100% !important;
        max-width: 100% !important;
    }
    #button-layout {
        margin-right: 0 !important;
        min-width: 100%;
        padding-bottom: 12px;
        display: flex !important;
        justify-content: center;
    }
    .quantity-input{
        width: auto;
    }
    #cancel-layout-big{
        display: none;
    }
    #cancel-layout-small{
        display: flex !important;
    }    
}
//Mobile to Tablet sized devices
@media only screen and (min-width: 320px) and (max-width: 767px) {
    #mobile-spacing-add-2-cart {
        margin-right: auto !important;
        margin-left: 15px !important;
    }
    #mobile-spacing-cart-pricing {
        margin-left: auto !important;
        margin-right: 15px !important;
    }
    .ord-add-to-cart-btn {
        height: 55px !important;
    }
    .itemslider {
            min-width: auto !important;
        }
    .view_all_item {
        width: 100% !important;
        min-width: 50% !important;
        max-width: 100% !important;
        display: grid !important;
    }
}
//Tablet to Desktop sized devices
@media only screen and (min-width: 768px) and (max-width: 1080px) {
    .view_all_item{
    width: 180px !important;
    min-width: 175px !important; 
    max-width: 175px !important;
    }
    .ulGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width:1130px){
    .max-width-item-details-page {
        max-width: 1100px;
    }
}

.category-image {
    height: 150px !important;
    border-radius: 20px;
}
.view-category-image {
    height: 120px !important;
}

.item-name{
    max-height: 50px;
    overflow: hidden;
    word-break: normal;
    line-height: 25px;
}

.break-all {
    word-break: break-all; /* Word-break: break-all style */
    padding-bottom: 5px;
}

.img-min-height {
    min-height: 150px;
}
.order-item-card-height{
    height: 278px !important;
    width: 100%;
}
.order-item-card-height.without-image, .view_all_item.without-image {
    height: 190px !important;
}
.order-item-card-height.without-image, .view_all_item.without-image-description {
    height: 190px !important;
}
@media only screen and (min-width: 768px) {
    .category-image {
        height: 150px !important;
    }
    .view-category-image {
        height: 150px !important;
    }
    .order-item-card-height {
        height: 278px !important;
        width: 100%;
    }
    .order-item-card-height.without-image {
        height: 130px !important;
    }
    .order-item-card-height.without-image-description {
        height: 190px !important;
    }
}
.card-img-top {
    border-radius: var(--bs-card-inner-border-radius) !important;
}

.no-box-shadow{
    box-shadow: none;
}

.search-input{
    background-color: var(--kt-input-solid-bg-focus) !important;
}