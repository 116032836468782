#id_me_btn_skin{
  background-color: #08833D;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 91%;
  height: 47px;
  border-radius: 10px;
  margin-bottom: 17px;
  cursor: pointer;
}
.id-me-text-styles{
  margin-top: 3px;
  margin-left: calc(40% - 50px);
  height:45px
}
@media only screen and (min-width: 651px) and (max-width: 850px) {
  .id-me-text-styles{
  margin-left: calc(38% - 50px);
}
}
@media only screen and (min-width: 482px) and (max-width: 650px) {
  .id-me-text-styles{
  margin-left: calc(35% - 50px);
}
}
@media only screen and (min-width: 200px) and (max-width: 481px) {
  .id-me-text-styles{
  margin-left: calc(28% - 50px);
}
}